<template>
    <div>
        <styled-interface>
            <template #interface-heading>
                <h2>Custom Table Demo</h2>
            </template>
            <styled-card class="mb-4">
                <template #heading>
                    Custom Table Demo
                </template>
                <template #action-buttons>
                    <action-button icon="download">
                        Export CSV
                    </action-button>
                    <action-button
                        icon="refresh"
                        @click.prevent="initResetFields">
                        Reset Filters
                    </action-button>
                </template>

                <custom-table
                    :items="vehicles"
                    :fields="fields"
                    :actions="actions"
                    :hide-header="false"
                    :hide-search="false"
                    :hide-sort="false"
                    :expandable="true"
                    :has-filters="true"
                    :has-totals="true"
                    :selectable="true"
                    :has-menu="true"
                    :has-customization="true"
                    :reset="reset"
                    :rows-per-page="4"
                    name="demo-table"
                    min-width="1200px"
                    item-key="vin">
                    <template #expand="{ item }">
                        <div class="expandable-area">
                            Some VIN: {{ item.vin }}
                        </div>
                    </template>
                    <template #menu="{ item }">
                        <styled-button
                            small
                            class="mb-4">
                            CTA {{ item.model }}
                        </styled-button>
                        <p>Menu content for ID: {{ item.id }}</p>
                        <ul>
                            <li>
                                <a>Link for {{ item.model }}</a>
                            </li>
                            <li>
                                <a>Link for {{ item.model }}</a>
                            </li>
                            <li>
                                <a>Link for {{ item.model }}</a>
                            </li>
                        </ul>
                    </template>
                </custom-table>
            </styled-card>
        </styled-interface>
    </div>
</template>

<script>
import CustomTable from '@/components/globals/CustomTable/index.vue';
import StyledButton from '@/components/globals/StyledButton.vue';
import StyledCard from '@/components/globals/StyledCard';
import StyledInterface from '@/components/globals/StyledInterface';
import ActionButton from '@/components/globals/ActionButton';

export default {
    name: 'CustomTableDemo',
    title: 'Table Component Demo',
    components: {
        CustomTable,
        StyledButton,
        StyledCard,
        StyledInterface,
        ActionButton,
    },
    data() {
        return {
            columnPresets: [],
            reset: false,
            actions: [
                {
                    label: 'Generate PDF',
                    callback: (selectedItems) => {
                        console.log('Generate PDF callback for such items:');
                        console.log(selectedItems);
                    },
                    showConfirmation: true,
                },
                {
                    label: 'Generate DOC',
                    callback: (selectedItems) => {
                        console.log('Generate DOC callback for such items:');
                        console.log(selectedItems);
                    },
                    showConfirmation: true,
                },
                {
                    label: 'Without confirmation',
                    callback: (selectedItems) => {
                        console.log('Callback activated for such items without confirmation:');
                        console.log(selectedItems);
                    },
                    showConfirmation: false,
                },
            ],
            fields: [
                {
                    id: 'id',
                    header: 'ID',
                    value: 'id',
                    width: '120px',
                    align: 'left',
                    sortable: true,
                    filter: '',
                    type: 'single',
                    description: 'some description blalalal'
                },
                {
                    id: 'make',
                    header: 'Vehicle Make',
                    value: 'make.top',
                    align: 'right',
                    width: '170px',
                    sortable: true,
                    filter: 'search',
                    type: 'single',
                },
                {
                    id: 'model',
                    header: {
                        top: 'Year',
                        bottom: 'Model',
                    },
                    value: {
                        top: 'year',
                        bottom: 'model',
                    },
                    align: 'right',
                    width: '110px',
                    sortable: false,
                    filter: '',
                    type: 'stacked',
                },
                {
                    id: 'vin',
                    header: 'VIN',
                    value: 'vin',
                    align: 'right',
                    width: '190px',
                    sortable: true,
                    filter: 'search',
                    type: 'single',
                },
                {
                    id: 'year',
                    header: 'Year',
                    value: 'year',
                    align: 'right',
                    width: '120px',
                    sortable: true,
                    filter: 'search',
                    type: 'single',
                },
                {
                    id: 'condition',
                    header: 'Condition',
                    value: 'condition',
                    align: 'right',
                    width: '120px',
                    sortable: true,
                    filter: 'single',
                    type: 'single',
                },
                {
                    id: 'traffic',
                    header: 'Traffic',
                    value: 'traffic',
                    align: 'right',
                    width: '120px',
                    sortable: true,
                    filter: 'search',
                    type: 'single',
                    total: true,
                    average: true,
                    format: 'currency'
                },
                {
                    id: 'stock_number',
                    header: 'Stock No',
                    value: 'stock_number',
                    align: 'right',
                    width: '120px',
                    sortable: true,
                    total: true,
                    format: 'currency',
                    filter: 'multiple',
                    type: 'single',
                },
            ],
            vehicles: [
                {
                    id: 13434523423,
                    certified: 0,
                    condition: 'New',
                    content_views: 19,
                    created_at: '2020-10-17 12:48:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 64,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Ford',
                        bottom: 'Explorer',
                    },
                    model: 'Explorer',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'For Sale',
                    stock_number: '865',
                    traffic: 234,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15971',
                    website_leads: 0,
                    year: 2019,
                },
                {
                    id: 2934720340,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-10 09:48:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Nissan',
                        bottom: 'Rogue',
                    },
                    model: 'Rogue',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: '267',
                    traffic: 67,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15972',
                    website_leads: 0,
                    year: 2020,
                    total: 100,
                },
                {
                    id: 398579349530,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-05 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15973',
                    website_leads: 0,
                    year: 2021,
                    total: 10,
                },
                {
                    id: 2305930922656,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:37:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15974',
                    website_leads: 0,
                    year: 2022,
                },
                {
                    id: 98765394582378,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:27:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Corolla',
                    },
                    model: 'Corolla',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15975',
                    website_leads: 0,
                    year: 2022,
                },
                {
                    id: 375639482039,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 10:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15976',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 71653723469,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 09:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15977',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 9856480983454,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-05-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15978',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 823746349853094,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-07-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Hybrid',
                    },
                    model: 'Prius',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15979',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 49583702934,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2016-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15980',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 9348720394,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2018-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15981',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 32847340598,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2021-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15982',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 83274628734,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15983',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 394543345,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15984',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 605978405964,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15985',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 2834723902458,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15986',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 93845732094,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15987',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 394587345456456,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15988',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 3495872345645645,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-17 11:47:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: 42234,
                    traffic: 23,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15989',
                    website_leads: 0,
                    year: 2021,
                },
                {
                    id: 2347239434534546,
                    certified: 0,
                    condition: 'Used',
                    content_views: 19,
                    created_at: '2020-10-18 02:48:39',
                    days_seen: 117,
                    deleted_at: null,
                    engagement: 6784,
                    image:
                        'https://cdn.dlron.us/inventoryphotos/5366/1fmsk7bh9mga15975/ip/1_637450077510000000.jpg',
                    impressions: 3015,
                    leads: 0,
                    make: {
                        top: 'Toyota',
                        bottom: 'Camry',
                    },
                    model: 'Camry',
                    reach: 0,
                    sold: false,
                    spend: 38.292172,
                    status: 'Sold',
                    stock_number: '323',
                    traffic: 86,
                    trim: 'Explorer',
                    used: 0,
                    vin: '1FMSK7BH9MGA15990',
                    website_leads: 0,
                    year: 2018,
                },
            ]
        };
    },
    methods: {
        initResetFields() {
            this.reset = true;
            setTimeout(() => {
                this.reset = false;
            }, 100);
        }
    },
};
</script>
<style lang="scss" scoped>
.expandable-area {
    width: 100%;
}
.vertical-menu {
    .svg-fill {
        fill: #000;
        stroke: rebeccapurple;
    }
}
</style>
